import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Container, Paper, Grid, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ProgressBar from 'react-bootstrap/ProgressBar'

const answer = [{
    value: "1. 你的性别",
    options: [{
        content: "男",
        tot: 61
      },
      {
        content: "女",
        tot: 53
      }
    ]
  },
{value :"2.你的地区",options : [{content:"被隔离了",tot:80},{content:"放出来了",tot:34}]},
{value :"3.学段",options : [{content:"初高中",tot:43},{content:"经过了高考的磨砺",tot:71}]},
{value :"4.被抓去学校了吗",options : [{content:"被抓了",tot:38},{content:"在家呢",tot:76}]},
{value :"5.（未返校）上网课对于上课的效率的影响程度？",options : [{content:"学不下去了",tot:9},{content:"效率低了些",tot:47},{content:"在哪听课不一样吗？",tot:13},{content:"在家听课多爽，课简单了好多",tot:5}]},
{value :"5.（已返校）戴口罩对于上课的效率的影响程度？",options : [{content:"戴着口罩快憋死了，学不下去了",tot:2},{content:"效率低了些",tot:6},{content:"无所谓",tot:24}]},
{value :"6.（未返校）你期待开学么?",options : [{content:"在家要疯了",tot:55},{content:"千万别开学",tot:5},{content:"无所谓",tot:6}]},
{value :"7.（未返校）疫情期间上网课和学习的状态",options : [{content:"走神",tot:13},{content:"认真",tot:46},{content:"边看剧边刷网课",tot:17}]},
{value :"8.（未返校）您认为网课更适合学习哪些内容?（多选）",options : [{content:"复习、巩固旧的知识",tot:67},{content:"学习新课",tot:25},{content:"网络答疑",tot:32},{content:"专题讲座",tot:12},{content:"其他",tot:3}]},
{value :"9.采购学习资料是否方便？",options : [{content:"出不去买不到啊",tot:16},{content:"网购学习资料真香",tot:86},{content:"学校集体采购",tot:12}]},
{value :"10.（限制初高中回答）是否有理想的大学？",options : [{content:"确定了",tot:23},{content:"没什么想法",tot:20}]},
{value :"11.疫情在家学习会给自己制定明确的学习计划吗？",options : [{content:"有计划,且一一实行",tot:25},{content:"有计划,但计划赶不上变化",tot:36},{content:"有意向但还没制定",tot:18},{content:"没有,随缘学习",tot:35}]},
{value :"12.真正在学习的时间的变化？（开小差不算哦~）",options : [{content:"很少",tot:5},{content:"较少",tot:26},{content:"没变",tot:62},{content:"增加了",tot:18},{content:"增加很多",tot:3}]},
{value :"13.你的学习排名？",options : [{content:"直线上升",tot:12},{content:"升高了",tot:15},{content:"差不多",tot:53},{content:"降低了",tot:29},{content:"一落千丈",tot:5}]},
{value :"14.升学就业的影响？(多选)(选做)",options : [{content:"考研更好考了",tot:12},{content:"工作好难找啊",tot:8},{content:"感觉没什么影响",tot:6}]},
{value :"15.你参与疫情期间的社会实践学习了吗？",options : [{content:"参加了",tot:76},{content:"未参加",tot:38}]},
{value :"16.通过什么途径学习了疫情期间的英雄事迹？(多选)",options : [{content:"报纸",tot:14},{content:"高考作文素材",tot:43},{content:"抖音",tot:63},{content:"网上冲浪（知乎、贴吧等）",tot:57}]}
]

const op12 = [
  {
    content:"减少了",
    tot:31
  },
  {
    content:"不变或增加",
    tot:83
  }
]
const op13 = [
  {
    content:"上升",
    tot:18
  },
  {
    content:"下降",
    tot:25
  }
]
const dz = [
  {
    content:"初高中",
    tot:32
  },
  {
    content:"大学",
    tot:6
  }
]
const dz12 = [
  {
    content:"有计划",
    tot:8
  },
  {
    content:"无计划",
    tot:23
  }
]
const dr12 = [
  {
    content:"有计划",
    tot: 53
  },
  {
    content:"无计划",
    tot:30
  }
]
const dz13 = [
  {
    content:"有理想大学",
    tot:14
  },
  {
    content:"没有理想大学",
    tot:4
  }
]
const dr13 = [
  {
    content:"有理想大学",
    tot: 9
  },
  {
    content:"没有理想大学",
    tot:16
  }
]
const dr = [
  {
    content:"初高中",
    tot:11
  },
  {
    content:"大学",
    tot:65
  }
]

 const totalpeople = 114;
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    top: '20%'
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#00e676',
  },
}))(LinearProgress);

function App() {
  return (
    <div className="App"> 
      <Container maxWidth="md" style={{}}>
        <h1>调查问卷统计结果</h1>
        <a>本报告可通过<a href="http://sx.xjzsq.ren">报告生成系统</a>在线查看</a>
        {answer.map((item,idx)=>{
          if(idx!=3&&idx!=12&&idx!=13)return(
            <Paper elevation={3} style={{marginTop:'12px'}} >
              <h3 style={{textAlign:'left',paddingLeft:'18px',paddingTop:'6px',marginButtom:'0'}}>{item.value}</h3>
              {item.options.map((option,id)=>{
                return(
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <a style={{paddingLeft:'6px'}}>{option.content}</a>
                      </Grid>
                      <Grid item xs={1}>
                        <a>{option.tot}</a>
                      </Grid>
                      <Grid item xs={5}>
                        <ProgressBar now={60} />
                        <BorderLinearProgress variant="determinate"  value={option.tot/totalpeople*100} />
                      </Grid>
                      <Grid item xs={2}>
                        <a>{(option.tot/totalpeople*100).toFixed(2)}%</a>
                      </Grid>
                    </Grid>
                  </div>
                  )
              })}
            </Paper>
            );
            if(idx==3)return(
            <div>
              <Paper elevation={3} style={{marginTop:'12px'}} >
                <h3 style={{textAlign:'left',paddingLeft:'18px',paddingTop:'6px',marginButtom:'0'}}>{item.value}</h3>
                {item.options.map((option,id)=>{
                  return(
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <a style={{paddingLeft:'6px'}}>{option.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{option.tot}</a>
                        </Grid>
                        <Grid item xs={5}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={option.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={2}>
                          <a>{(option.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                      </Grid>
                    </div>
                    )
                })}
              </Paper>
              <Paper elevation={3} style={{marginTop:'12px',paddingBottom:'18px'}} >
                <h3 style={{textAlign:'left',paddingLeft:'18px',paddingTop:'6px',marginButtom:'0'}}>{item.value}(与Q3学段结合分析)</h3>
                {item.options.map((option,id)=>{
                  return(
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <a style={{paddingLeft:'6px'}}>{option.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{option.tot}</a>
                        </Grid>
                        <Grid item xs={5}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={option.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={2}>
                          <a>{(option.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                      </Grid>
                        <Paper elevation={3} style={{margin:'12px 12px 12px 100px'}} >
                        {id==0?dz.map((fen,fid)=>{return(
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                          <a style={{paddingLeft:'6px'}}>{fen.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{fen.tot}</a>
                        </Grid>
                        <Grid item xs={6}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={fen.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={3}>
                          <a>{(fen.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                        </Grid>
                        )}):dr.map((fen,fid)=>{return(
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                          <a style={{paddingLeft:'6px'}}>{fen.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{fen.tot}</a>
                        </Grid>
                        <Grid item xs={6}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={fen.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={3}>
                          <a>{(fen.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                        </Grid>
                        )})}
                        </Paper>
                    </div>
                    )
                })}
              </Paper>
            </div>
            );
            if(idx==12)return(
              <div>
              <Paper elevation={3} style={{marginTop:'12px'}} >
                <h3 style={{textAlign:'left',paddingLeft:'18px',paddingTop:'6px',marginButtom:'0'}}>{item.value}</h3>
                {item.options.map((option,id)=>{
                  return(
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <a style={{paddingLeft:'6px'}}>{option.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{option.tot}</a>
                        </Grid>
                        <Grid item xs={5}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={option.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={2}>
                          <a>{(option.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                      </Grid>
                    </div>
                    )
                })}
              </Paper>
              <Paper elevation={3} style={{marginTop:'12px',paddingBottom:'18px'}}>
                <h3 style={{textAlign:'left',paddingLeft:'18px',paddingTop:'6px',marginButtom:'0'}}>{item.value}(与Q11进行比较分析)</h3>
                {op12.map((option,id)=>{
                  return(
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <a style={{paddingLeft:'6px'}}>{option.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{option.tot}</a>
                        </Grid>
                        <Grid item xs={5}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={option.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={2}>
                          <a>{(option.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                      </Grid>
                        <Paper elevation={3} style={{margin:'12px 12px 12px 100px'}} >
                        {id==0?dz12.map((fen,fid)=>{return(
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                          <a style={{paddingLeft:'6px'}}>{fen.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{fen.tot}</a>
                        </Grid>
                        <Grid item xs={6}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={fen.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={3}>
                          <a>{(fen.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                        </Grid>
                        )}):dr12.map((fen,fid)=>{return(
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                          <a style={{paddingLeft:'6px'}}>{fen.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{fen.tot}</a>
                        </Grid>
                        <Grid item xs={6}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={fen.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={3}>
                          <a>{(fen.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                        </Grid>
                        )})}
                        </Paper>
                    </div>
                    )
                })}
              </Paper>
              </div>
            );
            if(idx==13)return(
              <div>
              <Paper elevation={3} style={{marginTop:'12px'}} >
                <h3 style={{textAlign:'left',paddingLeft:'18px',paddingTop:'6px',marginButtom:'0'}}>{item.value}</h3>
                {item.options.map((option,id)=>{
                  return(
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <a style={{paddingLeft:'6px'}}>{option.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{option.tot}</a>
                        </Grid>
                        <Grid item xs={5}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={option.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={2}>
                          <a>{(option.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                      </Grid>
                    </div>
                    )
                })}
              </Paper>
              <Paper elevation={3} style={{marginTop:'12px',paddingBottom:'18px'}}>
                <h3 style={{textAlign:'left',paddingLeft:'18px',paddingTop:'6px',marginButtom:'0'}}>{item.value}(与Q10进行比较分析)</h3>
                {op13.map((option,id)=>{
                  return(
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <a style={{paddingLeft:'6px'}}>{option.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{option.tot}</a>
                        </Grid>
                        <Grid item xs={5}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={option.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={2}>
                          <a>{(option.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                      </Grid>
                        <Paper elevation={3} style={{margin:'12px 12px 12px 100px'}} >
                        {id==0?dz13.map((fen,fid)=>{return(
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                          <a style={{paddingLeft:'6px'}}>{fen.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{fen.tot}</a>
                        </Grid>
                        <Grid item xs={6}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={fen.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={3}>
                          <a>{(fen.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                        </Grid>
                        )}):dr13.map((fen,fid)=>{return(
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                          <a style={{paddingLeft:'6px'}}>{fen.content}</a>
                        </Grid>
                        <Grid item xs={1}>
                          <a>{fen.tot}</a>
                        </Grid>
                        <Grid item xs={6}>
                          <ProgressBar now={60} />
                          <BorderLinearProgress variant="determinate"  value={fen.tot/totalpeople*100} />
                        </Grid>
                        <Grid item xs={3}>
                          <a>{(fen.tot/totalpeople*100).toFixed(2)}%</a>
                        </Grid>
                        </Grid>
                        )})}
                        </Paper>
                    </div>
                    )
                })}
              </Paper>
              </div>
            );
        })}
        <div style={{height:"100px"}}>
        </div> 
      </Container>
    </div>
  );
}

export default App;